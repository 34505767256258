<template>
  <div 
    v-botui-container
    :class="{ dark }"
    class="botui botui-container"
  >
    <div class="botui-messages-container pb-8">
      <div
        v-for="(msg, i) in chat"
        :key="'message-' + i"
        class="botui-message"
        :class="[{ human: msg.human, bot: !msg.human }, msg.cssClass]"
        v-botui-scroll
      >
        <v-scroll-x-reverse-transition>
          <v-card
            v-if="msg.visible"
            elevation="1"
            color="grey darken-3"
            :class="[
              { human: msg.human, 'botui-message-content': true },
              msg.type,
            ]"
            class="d-inline-flex align-end"
            @click="messageClick(msg)"
          >
            <span
              v-if="msg.type == 'text'"
              v-text="msg.content"
              v-botui-markdown
            ></span>
            <iframe
              v-if="msg.type == 'embed'"
              :src="msg.content"
              frameborder="0"
              allowfullscreen
              scrolling="no"
            ></iframe>
            <div 
              v-if="!msg.hasOwnProperty('image')"
              class="botui-message-footer text-right"
            >
              <span 
                v-if="!msg.loading&&msg.hasOwnProperty('timestamp')"
                class="botui-message-timestamp ml-2 text-caption text-no-wrap">
                {{ msg.timestamp | formatTimestamp }}
              </span>
              <v-icon 
                v-if="msg.human"
                small
                class="botui-message-read ml-2"
              >
                {{ icons.read }}  
              </v-icon>
            </div>
          </v-card>
        </v-scroll-x-reverse-transition>
        <div v-if="msg.loading" class="botui-message-content loading"> 
          <i class="dot">&#8226;</i>
          <i class="dot">&#8226;</i>
          <i class="dot">&#8226;</i>
        </div>
      </div>
    </div>
    <slot name="footer" />
    <div 
      ref="actions"
      class="botui-actions-container text-right mt-2 pb-4"
    >
      <v-slide-x-reverse-transition>
        <div v-if="action.show" v-botui-scroll>
          <form
            v-if="action.type == 'text'"
            class="botui-actions-form botui-actions-text"
            @submit.prevent="handle_action_text()"
            :class="action.cssClass"
          >
            <v-textarea
              v-if="action.text.sub_type == 'area'"
              v-model="action.text.value"
              :label="action.text.placeholder"
              :type="action.text.sub_type"
              :size="action.text.size"
              :disabled="!online"
              :append-icon="icons.send"
              required
              hide-details
              outlined
              auto-grow
              rows="1"
              color="primary"
              ref="textarea"
              :class="action.text.cssClass"
              class="botui-actions-text-area"
              @click:append="handle_action_text"
            >
              <template 
                v-if="action.text.attachable"
                v-slot:prepend-inner
              >
                <v-menu
                  v-model="action.menu.toggle"
                  top
                  offset-y
                  offset-x
                  nudge-top="12"
                  nudge-right="16"
                  left
                  close-delay="250"
                  min-width="80vw"
                  elevation="4"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">{{ icons.file }}</v-icon>
                  </template>
                  <v-sheet
                    color="grey darken-3"
                  >
                    <v-file-input
                      v-model="action.file.value"
                      prepend-icon=""
                      hide-details
                      multiple
                      outlined
                      label="Selecionar arquivo(s)..."
                      class="botui-actions-menu-file-input"
                      @change="handle_action_file"
                    />
                  </v-sheet>
                </v-menu>
              </template>
            </v-textarea>
            
            <v-card
              v-else-if="action.text.sub_type=='time'"
              outlined
              class="d-flex align-center justify-space-between"
              @input="validateText"
            >
              <span class="text--secondary text-caption pa-4">
                Hora
              </span>
              <v-select 
                v-model="action.text.value.hour"
                :items="time.hours"
                hide-details
                solo
                flat
                menu-props="auto"
                append-icon=""
                class="timepicker hours"
              />
              :
              <v-select 
                v-model="action.text.value.minute"
                :items="time.minutes"
                hide-details
                solo
                flat
                append-icon=""
                class="timepicker minutes"
              />
              <v-btn
                icon
                color="primary"
                class="mx-2"
                @click="handle_action_text"
              >
                <v-icon>{{ icons.send }}</v-icon>
              </v-btn>
            </v-card>
            <v-card
              v-else-if="action.text.sub_type=='date'"
              color="grey darken-3"
              class="d-flex align-center justify-center pt-4"
            >
              <v-date-picker
                v-model="action.text.value"
                :max="today"
                :show-current="false"
                :events="dateHint"
                event-color="secondary"
                no-title
                class="datepicker"
                @input="validateText"
              />
              <v-btn
                fab
                small
                absolute
                bottom
                right
                :disabled="action.validated!==true"
                color="primary"
                class="mb-8"
                @click="handle_action_text"
              >
                <v-icon>{{ icons.send }}</v-icon>
              </v-btn>
            </v-card>
            <v-text-field
              v-else
              v-model="action.text.value"
              :label="action.text.placeholder"
              :type="action.text.sub_type"
              :size="action.text.size"
              :append-icon="action.validated ? icons.send : undefined"
              :disabled="!online"
              hide-details
              required
              outlined
              color="primary"
              ref="input"
              accept="image/*"
              capture="camera"
              :class="action.text.cssClass"
              class="botui-actions-text-input"
              @input="validateText"
              @click:append="handle_action_text"
            />
          </form>
          <form
            v-else-if="action.type == 'select'"
            class="botui-actions-form botui-actions-select ml-auto mr-2"
            :class="action.cssClass"
          >
            <v-select
              v-model="action.select.value"
              :value="action.select.value"
              :placeholder="action.select.placeholder"
              :label="action.select.label"
              :items="action.select.options"
              :disabled="!online"
              hide-details
              outlined
              color="primary"
              class="botui-actions-text-searchselect"
              @input="handle_action_select"
            />
          </form>
          <div
            v-else-if="action.type == 'button'"
            class="botui-actions-form botui-actions-buttons ml-auto mr-n2"
            :class="action.cssClass"
          >
            <v-card
              v-for="(button, i) in action.button.buttons"
              :key="'btn-' + i"
              v-botui-markdown
              large
              outlined
              :class="[
                button.icon ? 'botui-actions-buttons-button-icon' : '',
                button.cssClass,
                !online ? 'offline' : ''
              ]"
              class="botui-actions-buttons-button pa-2 px-3 mb-2 mr-2"
              @click="online ? handle_action_button(button) : void(0)"
            >
              <!-- <i
                v-if="button.icon"
                class="botui-icon botui-action-button-icon fa"
                :class="'fa-' + button.icon"
                :style="'background-image:url(' + button.icon + ')'"
              ></i> -->
              {{ button.text }}
            </v-card>
          </div>
          <div
            v-else-if="action.type == 'calendar'"
            class="botui-actions-form botui-actions-calendar"
            :class="action.cssClass"
          >
            <calendar-view
              :slots="action.calendar.options"
              @select="handle_action_calendar"
            />
          </div>
          <div
            v-else-if="action.type == 'hours'"
            class="botui-actions-form botui-actions-calendar"
            :class="action.cssClass"
          >
            <calendar-view
              :slots="action.hours.options"
              multiple
              @select="handle_action_hours"
              @toggle-alert="toggleAlert"
            />
          </div>
          <div
            v-else-if="action.type == 'file'"
            class="botui-actions-form botui-actions-file ml-auto"
            :class="action.cssClass"
          >
            <v-btn 
              v-if="action.file.sub_type=='camera'"
              block
              large
              color="primary"
              class="btn-photo grey--text text--darken-4"
              @click="handle_action_camera"
            >
              Tirar foto
            </v-btn>
            <v-file-input
              v-else
              v-model="action.file.value"
              prepend-icon=""
              :prepend-inner-icon="icons.file"
              hide-details
              multiple
              outlined
              label="Selecionar arquivo(s)..."
              class="botui-actions-file-input mr-2"
              @change="handle_action_file"
            />
          </div>
        </div>
      </v-slide-x-reverse-transition>
    </div>
    <v-dialog 
      v-model="gallery.toggle"
      fullscreen 
      transition="slide-y-reverse-transition"
    >
      <v-sheet
        height="100%"
        class="pt-14"
      >
        <v-toolbar 
          flat 
          absolute
          width="100%"
          color="transparent"
          class="page-header"
        >
          <v-icon color="grey">{{ icons.image }}</v-icon>
          <v-toolbar-title class="title text-body-1 grey--text ml-4">
            {{ gallery.label }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click.stop="toggleGallery(false)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img 
          :src="gallery.url" 
          contain
          height="100%"
          class="full-image"
        />
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
/*eslint no-useless-escape: "off"*/
var _instance, // current vue instance.
  _options = {
    debug: false,
    searchselect: false,
  },
  _container, // the outermost Element. Needed to scroll to bottom, for now.
  _interface = {}, // methods returned by a BotUI() instance.
  _actionResolve,
  _markDownRegex = {
    image: /!\[(.*?)\]\((.*?)\),?/gim, // ![alternate text](src)
    link: /\[([^\[]+)\]\(([^\)]+)\)(\^?)/gim, // [text](link) ^ can be added at end to set the target as 'blank'
    audio: /!\(([^\)]+\.mp3)\)/gim, // !(audio.mp3)
    bold: /\*([^\*]+)\*/gim, // *bold*
    emphasis: /\*\*([^\*]+)\*\*/gim, // **emphasis**
    br: /\n/gim,
  }

function _linkReplacer(match, $1, $2, $3) {
  var _target = $3 ? "blank" : ""; // check if '^' sign is present with link syntax
  return (
    "<a class='botui-message-content-link' href='" +
    $2 +
    "'>" +
    $1 +
    "</a>"
  );
}

function _parseMarkDown(text) {
  return text
    .replace(/^\n/gim, '')
    .replace(
      _markDownRegex.audio,
      "<audio autoplay class='botui-message-content-audio' src='$1'></audio>"
    )
    .replace(
      _markDownRegex.image,
      "<img class='botui-message-content-image' src='$2' alt='$1' @click='toggleGallery' />"
    )
    .replace(_markDownRegex.link, _linkReplacer)
    .replace(_markDownRegex.bold, "<b>$1</b>")
    .replace(_markDownRegex.emphasis, "<em>$1</em>")
    .replace(_markDownRegex.br, "<br/>");
}

function loadScript(src, cb) {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = src;

  if (cb) {
    script.onload = cb;
  }

  document.body.appendChild(script);
}

function _handleAction(text) {
  if (_instance.action.addMessage) {
    _interface.message.human({
      delay: 250,
      content: text,
    });
  }
  _instance.action.show = !_instance.action.autoHide;
}

function updateCss (selector, property, value) {
  var css = document.styleSheets;
  for (var i=0; i<css.length; i++) {
    if (!css[i].href) {
      var rules = css[i].cssRules;
      for (var j=0; j<rules.length; j++) {
          if (rules[j].selectorText === selector) {
              rules[j].style[property] = value;
          }
      }
    }
  }
}

import { mdiSend, mdiPaperclip, mdiClose, mdiMessageImageOutline, mdiCheckAll } from '@mdi/js';
// import {  
//   Plugins,  
//   CameraSource,  
//   CameraResultType,
//   CameraDirection
// } from "@capacitor/core";
// const { Camera, Browser } = Plugins;
import { Camera, CameraSource, CameraResultType, CameraDirection } from '@capacitor/camera';
import { Browser } from '@capacitor/browser';
import services from '@/services'
const moment = require('moment');

export default {
  name: "BotUi",
  components: {
    CalendarView: () => import('@/components/CalendarView'),
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    history: {
      type: Array,
      default: () => []
    },
    allowedDates: {
      type: Array,
      default: () => null
    },
    container: {
      type: HTMLElement,
      default: () => null
    },
    online: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      action: {
        text: {
          value: '',
          size: 30,
          placeholder: "...",
        },
        button: {},
        calendar: {},
        hours: {},
        file: {},
        menu: {
          toggle: false,
        },
        show: false,
        type: "text",
        autoHide: true,
        addMessage: true,
        validated: true
      },
      options: {
        time: {
          hours: [...Array(24).keys()],
          minutes: [0, 10, 20, 30, 40, 50],
        },
      },
      gallery: {
        toggle: false,
        url: null,
        label: null
      },
      min: null,
      max: null,
      today: null,
      scrolling: false,
      scroller: null,
      messages: [],
      icons: {
        send: mdiSend,
        file: mdiPaperclip,
        close: mdiClose,
        image: mdiMessageImageOutline,
        read: mdiCheckAll
      }
    };
  },
  computed: {
    chat () {
      const loading = this.loading;
      const chat = _.reject(_.clone(this.messages), ['hidden', true]);
      return [...chat, ...(loading ? [{ loading }] : [])];
    },
    isMobile () {
      return root.innerWidth && root.innerWidth <= 768;
    },
    time () {
      const min = _.has(this.action.text, 'min') ? _.split(this.action.text.min, ':') : null;
      const max = _.has(this.action.text, 'max') ? _.split(this.action.text.max, ':') : null;
      const selected = this.action.text.value;
      return {
        hours: _.map(this.options.time.hours, h => {
          return {
            text: moment(h, 'HH').format('HH'),
            value: moment(h, 'HH').format('HH'),
            disabled: (!_.isNil(min) && parseInt(min[0])>h || (parseInt(min[0])==h && parseInt(min[1])>=_.last(this.options.time.minutes))) || !_.isNil(max) && parseInt(max[0])<h
          }
        }),
        minutes: _.map(this.options.time.minutes, m => {
          return {
            text: moment(m, 'mm').format('mm'),
            value: moment(m, 'mm').format('mm'),
            disabled: (!_.isNil(min) && (parseInt(min[0])==parseInt(selected.hour) && parseInt(min[1])>m)) || !_.isNil(max) && (parseInt(min[0])==parseInt(selected.hour) && parseInt(max[1])<m)
          }
        }),
      }
    },
    dateHint () {
      const duration = [...Array(moment(this.max).diff(this.min, 'd')+1).keys()];
      return _.isNil(this.allowedDates)||_.isEmpty(this.allowedDates) ? _.map(duration, i => moment(this.min).add(i, 'd')) : this.allowedDates;
    },
    installed () {
      return typeof mobees != 'undefined';
    },
  },
  watch: {
    history: {
      immediate: true,
      handler (history) {
        // console.log(history);
        this.messages = _.map(history, m => {
          const [has, label, image] = _markDownRegex.image.exec(m.content) || [false];
          // console.log(image);
          return {
            visible: true, 
            image: has ? { label, url: image } : null,
            type: !_.has(m, 'type') ? 'text' : m.type,
            ...m 
          }
        });
      }
    },
    'action.text.value': {
      deep: true,
      handler (value) {
        if (_.isObject(value)&&_.has(value, 'hour')&&_.has(this.action.text, 'min')) {
          const min = _.split(this.action.text.min, ':');
          const selected = value;
          this.action.text.value.minute = parseInt(min[0])==parseInt(selected.hour) && parseInt(min[1]) > parseInt(selected.minute) ? _.find(this.time.minutes, m => parseInt(m.value)>min[1]).value : selected.minute;
        }
      }
    }
  },
  methods: {
    messageClick (msg) {
      setTimeout(($, msg) => {
        if (_.has(msg, 'image')&&!_.isNil(msg.image)) {
          $.toggleGallery(true, msg.image);
        }else if (_.has(msg, 'link')&&!_.isNil(msg.link)) {
          Browser.open({ url: msg.link });
        }
      }, 250, this, msg);
    },
    toggleGallery (b, image) {
      this.gallery.url = _.isNil(image) ? null : image.url;
      this.gallery.label = _.isNil(image) ? null : image.label;
      this.gallery.toggle = _.isNil(b) ? !this.gallery.toggle : b;
    },
    validateText () {
      if (this.action.text.sub_type=='date') {
        let validated = true;
        // console.log(this.action.text.value)
        const d = moment(this.action.text.value);
        if (!(_.isNil(this.allowedDates)||_.isEmpty(this.allowedDates))) {
          if (_.indexOf(this.allowedDates, d.format('YYYY-MM-DD'))<0) {
            validated = 'Neste dia, o defeito não havia sido reportado ou a manutenção foi reagendada.';
          }
        }else{
          if (moment(this.max).isBefore(d.format('YYYY-MM-DD'))) {
            validated = 'É necessário aguardar 2 dias antes de enviar o seu chamado, para o Buzzer processar as horas armazenadas.';
          }else if (moment(this.min).isAfter(d.format('YYYY-MM-DD'))) {
            validated = 'Prazo máximo para solicitar compensação: 9 dias após o ocorrido.';
          }
        }
        if (validated!==true) {
          this.$emit('toggle-alert', validated);
          this.action.validated = false;
        }else{
          this.action.validated = validated;
          this.$emit('toggle-alert', false);
        }
      }
    },

    handle_action_button: function (button) {
      _handleAction(button.text);
      var defaultActionObj = {
        type: "button",
        text: button.text,
        value: button.value,
      };

      for (var eachProperty in button) {
        if (button.hasOwnProperty(eachProperty)) {
          if (
            eachProperty !== "type" &&
            eachProperty !== "text" &&
            eachProperty !== "value"
          ) {
            defaultActionObj[eachProperty] = button[eachProperty];
          }
        }
      }

      _actionResolve(defaultActionObj);
    },
    handle_action_text_subtype (action) {
      if (action.text.hasOwnProperty('sub_type') && action.text.sub_type=='date') {
        const d = moment(action.text.value);
        action.text.message = 'Dia '+(d.date())+'/'+(d.month()+1);
      }else if (action.text.hasOwnProperty('sub_type') && action.text.sub_type=='time') {
        action.text.message = action.text.value = action.text.value.hour + ':' + action.text.value.minute;
      }else{
        action.text.message = action.text.value;
      }
      return action;
    },
    handle_action_text: function () {
      if (!this.action.text.value) return;
      const action = this.handle_action_text_subtype(this.action);
      let response = {
        type: "text",
        text: action.text.message,
        value: this.action.text.value,
        next: this.action.text.next || true
      };
      if (_.has(action.text, 'data')) response['data'] = action.text.data;
      _handleAction(action.text.message);
      _actionResolve(response);
      this.action.text.value = "";
    },
    handle_action_calendar: function (value) {
      if (!value) return;
      const d = moment(value.start);
      const message = 'Dia '+(d.date())+'/'+(d.month()+1)+' às '+d.hour()+'h';
      _handleAction(message);
      _actionResolve({
        type: "calendar",
        text: message,
        value: value,
      });
      this.action.calendar.options = {};
    },
    handle_action_hours: function (value) {
      if (!value) return;
      let slots = _.groupBy(value, hour => moment(hour.start).format('YYYY-MM-DD'));
      slots = _.mapValues(slots, day => {
        return _.keyBy(day, hour => moment(hour.start).hour());
      });
      const message = _.reduce(slots, (text, hours, day) => {
        const d = moment(day);
        hours = _.map(hours, (hour, h) => parseInt(h)).sort((a,b) => (a-b));
        const intervals = _.reduce(hours, (comp, h, i) => {
          h = parseInt(h);
          if (comp.last==null) {
            comp.last = h;
            comp.start = h;
            comp.text += hours.length==1 ? h+'h' : h;
          }else {
            if (comp.last+1<h) {
              if (comp.last==comp.start) {
                comp.text += 'h';
              }else{
                comp.text += '-'+(comp.last+1)+'h';
              }
              comp.text += ', '+(hours.length==i+1 ? h+'h' : h);
              comp.start = h;
            }else if (hours.length==i+1) {
              comp.text += '-'+(h+1)+'h';
            }
            comp.last = h;
          }
          return comp;
        }, { text: '', start: null, last: null });
        text += 'Dia '+(d.date())+'/'+(d.month()+1)+': '+intervals.text+'. ';
        return text;
      }, '');
      _handleAction(message);
      _actionResolve({
        type: "hours",
        text: message,
        value: value,
      });
      this.action.hours.options = {};
    },
    handle_action_file: async function (files) {
      if (!files) return;
      // if image show thumb message
      let message;
      console.log('files', files);
      if (_instance.action.file.sub_type=='camera') {
        files = [{ name: _instance.action.file.name+' '+moment().format('YYYY-MM-DD')+'.'+files.format, result: files.dataUrl }]
        message = `![${files[0].name}](${files[0].result})`
      }else{
        for await (let file of files) {
          console.log('file', file);
          file.result = await _instance.handle_action_file_reader(file);
        }
      }
      files = _.map(files, f => {
        return { name: services.slugify(f.name, true), file: f.result }
      });
      message = _.join(_.map(files, f => /\.(jpe?g|png|gif)$/i.test(f.name) ? `![${f.name}](${f.file})` : `*${f.name}*`), ', ');
      _handleAction(message);
      _actionResolve({
        type: "file",
        text: message,
        value: files,
      });

      this.action.file = {};
    },
    handle_action_camera: async function () {
      const state = JSON.parse(localStorage.getItem('camera'));
      if (!!state&&state.ticket==this.id&&state.filename==_instance.action.file.name) {
        localStorage.removeItem('camera');
        _instance.handle_action_file(state.data);
      }else{
        try {
          if (_instance.action.file.source=='CAMERA'&&!_instance.installed) {
            setTimeout(($) => {
              $.preventCameraPicker();
            }, 100, _instance);
          }
          const ticket = this.id;
          const filename = _instance.action.file.name;
          localStorage.setItem('camera', JSON.stringify({ ticket, filename, data: null }));
          const photo = await Camera.getPhoto(_instance.action.file);
          localStorage.removeItem('camera');
          _instance.handle_action_file(photo);
        }catch (e) {
          console.log('handle_action_camera', e);
        }
      }
    },
    handle_action_file_reader: (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      })
    },
    preventCameraPicker () {
      console.log('preventCameraPicker');
      const container = document.getElementsByTagName('pwa-camera-modal-instance');
      let camera = null;
      if (!_.isEmpty(container)) {
        try {
          container[0].shadowRoot.childNodes.forEach((n) => {
            if (_.indexOf(n.classList, 'wrapper')>=0) {
              camera = n.childNodes[0].childNodes[0].shadowRoot;
            }
          })
        } catch (error) {
          console.log(error);
        }
      }
      if (!_.isNil(camera)) {
        const css = document.createElement("style");
        css.textContent = ".pick-image { display: none !important; }"
        camera.appendChild(css)
      }else{
        setTimeout(($) => {
          $.preventCameraPicker();
        }, 100, _instance);
      }
    },
    handle_action_select: function () {
      // console.log(this.action.select);
      if (
        this.action.select.searchselect &&
        !this.action.select.multipleselect
      ) {
        if (!this.action.select.value.value) return;
        _handleAction(this.action.select.value[this.action.select.label]);
        _actionResolve({
          type: "text",
          value: this.action.select.value.value,
          text: this.action.select.value.text,
          obj: this.action.select.value,
        });
      }
      if (
        this.action.select.searchselect &&
        this.action.select.multipleselect
      ) {
        if (!this.action.select.value) return;
        var values = new Array();
        var labels = new Array();
        for (var i = 0; i < this.action.select.value.length; i++) {
          values.push(this.action.select.value[i].value);
          labels.push(this.action.select.value[i][this.action.select.label]);
        }
        _handleAction(labels.join(", "));
        _actionResolve({
          type: "text",
          value: values.join(", "),
          text: labels.join(", "),
          obj: this.action.select.value,
        });
      } else {
        if (!this.action.select.value) return;
        for (var j = 0; j < this.action.select.options.length; j++) {
          // Find select title
          if (this.action.select.options[j].value == this.action.select.value) {
            _handleAction(this.action.select.options[j].text);
            _actionResolve({
              type: "text",
              value: this.action.select.value,
              ...this.action.select.options[j],
            });
          }
        }
      }
    },
    toggleAlert (msg) {
      this.$emit('toggle-alert', msg);
    },
  },
  directives: {
    "botui-markdown": function (el, binding) {
      if (binding.value == false || el.getAttribute("botui-markdown-done"))
        return; // v-botui-markdown="false"
      el.innerHTML = _parseMarkDown(el.textContent);
      el.setAttribute("botui-markdown-done", true); // mark the node as already parsed
    },
    "botui-scroll": {
      inserted: function () {
        if (!_instance.scrolling) {
          _instance.scrolling = true;
          setTimeout(() => {
            _instance.scroller = null;
            // _container.scrollTop = _container.scrollHeight;
            const target = _instance.action.show && _.has(_instance.$refs, 'actions') ? _instance.$refs.actions : _container.scrollHeight;
            _instance.$vuetify.goTo(target, {
              container: _container,
              duration: 200,
              offset: 96,
              easing: 'easeInOutCubic'
            });
            setTimeout(() => {
              _instance.scrolling = false;
            }, 250);
          }, 500);
        }
      },
    },
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
    "botui-container": {
      inserted: function (el, binding, vnode) {
        const c = vnode.context.container;
        _container = c!=null ? c : el;
      },
    },
  },
  created () {
    _instance = this;
  },
  mounted () {
    this.min = moment().subtract(8, 'd').format('YYYY-MM-DD');
    this.max = moment().subtract(2, 'd').format('YYYY-MM-DD');
    this.today = moment().format('YYYY-MM-DD');
  },
  beforeDestroy () {
    _instance.action.show = false;
    _instance.action.button = {};
    _instance.action.file = {};
    _instance.action.calendar = {};
    _instance.action.errors = [];
    _instance.messages = [];
    if (_instance.scroller!=null) {
      clearTimeout(_instance.scroller);
      _instance.scroller = null;
    }
    console.log('BotUI cleaned up');
  },
  filters: {
    formatTimestamp (timestamp) {
      const time = moment.utc(timestamp).local();
      const yesterday = moment().subtract(1, 'd');
      return (moment().isSame(time, 'day') ? '' : yesterday.isSame(time, 'day') ? 'Ontem às ' : time.format('DD/MM') + ' ' ) + time.format('HH:mm');
    }
  }
};

function _addMessage(_msg) {
  if (!_msg.loading && !_msg.content) {
    throw Error(
      'BotUI: "content" is required in a non-loading message object.'
    );
  }
  const [has, label, image] = _markDownRegex.image.exec(_msg.content) || [false];
  // console.log(image);
  
  _msg.image = has ? { label, url: image } : null;
  _msg.type = _msg.type || "text";
  _msg.visible = _msg.delay || _msg.loading ? false : true;
  var _index = _instance.messages.push(_msg) - 1;

  return new Promise(function (resolve) {
    setTimeout(function () {
      if (_msg.delay) {
        _msg.visible = true;

        if (_msg.loading) {
          _msg.loading = false;
        }
      }
      resolve(_index);
    }, _msg.delay || 0);
  });
}

function _checkOpts(_opts) {
  if (typeof _opts === "string") {
    _opts = {
      content: _opts,
    };
  }
  return _opts || {};
}

_interface.message = {
  add: function (addOpts) {
    return _addMessage(_checkOpts(addOpts));
  },
  bot: function (addOpts) {
    addOpts = _checkOpts(addOpts);
    return _addMessage(addOpts);
  },
  human: function (addOpts) {
    addOpts = _checkOpts(addOpts);
    addOpts.human = true;
    return _addMessage(addOpts);
  },
  get: function (index) {
    return Promise.resolve(_instance.messages[index]);
  },
  remove: function (index) {
    _instance.messages.splice(index, 1);
    return Promise.resolve();
  },
  update: function (index, msg) {
    // only content can be updated, not the message type.
    var _msg = _instance.messages[index];
    _msg.content = msg.content;
    _msg.visible = !msg.loading;
    _msg.loading = !!msg.loading;
    return Promise.resolve(msg.content);
  },
  removeAll: function () {
    _instance.messages.splice(0, _instance.messages.length);
    return Promise.resolve();
  },
};

function mergeAtoB(objA, objB) {
  for (var prop in objA) {
    if (!objB.hasOwnProperty(prop)) {
      objB[prop] = objA[prop];
    }
  }
}

function _checkAction(_opts) {
  if (!_opts.action && !_opts.actionButton && !_opts.actionText) {
    throw Error('BotUI: "action" property is required.');
  }
}

function _showActions(_opts) {
  _checkAction(_opts);

  mergeAtoB(
    {
      type: "text",
      cssClass: "",
      autoHide: true,
      addMessage: true,
    },
    _opts
  );

  _instance.action.type = _opts.type;
  _instance.action.cssClass = _opts.cssClass;
  _instance.action.autoHide = _opts.autoHide;
  _instance.action.addMessage = _opts.addMessage;

  return new Promise(function (resolve) {
    _actionResolve = resolve; // resolved when action is performed, i.e: button clicked, text submitted, etc.
    setTimeout(function () {
      _instance.action.show = true;
    }, _opts.delay || 0);
  });
}

_interface.action = {
  show: _showActions,
  hide: function () {
    _instance.action.show = false;
    return Promise.resolve();
  },
  text: function (_opts) {
    _checkAction(_opts);
    if (_opts.action.hasOwnProperty('sub_type') && _opts.action.sub_type=='date') {
      _opts.action.value = _.isNil(_instance.allowedDates)||_.isEmpty(_instance.allowedDates) ? _instance.max : _.last(_instance.allowedDates.sort());
    }
    if (_opts.action.hasOwnProperty('sub_type') && _opts.action.sub_type=='time') {
      const time = _.split(_opts.action.value, ':');
      _opts.action.value = { hour: time[0], minute: time[1] };
    }
    if (!_opts.action.hasOwnProperty('attachable')) _opts.action.attachable = true;
    _instance.action.text = _opts.action;
    return _showActions(_opts);
  },
  button: function (_opts) {
    _checkAction(_opts);
    _opts.type = "button";
    _instance.action.button.buttons = _opts.action;
    return _showActions(_opts);
  },
  select: function (_opts) {
    _checkAction(_opts);
    _opts.type = "select";
    _opts.action.label = _opts.action.label || "text";
    _opts.action.value = _opts.action.value || "";
    _opts.action.searchselect =
      _opts.action.searchselect || _options.searchselect;
    _opts.action.multipleselect = _opts.action.multipleselect || false;
    if (_opts.action.searchselect && typeof _opts.action.value == "string") {
      if (!_opts.action.multipleselect) {
        for (var i = 0; i < _opts.action.options.length; i++) {
          // Find object
          if (_opts.action.options[i].value == _opts.action.value) {
            _opts.action.value = _opts.action.options[i];
          }
        }
      } else {
        var vals = _opts.action.value.split(",");
        _opts.action.value = new Array();
        for (var k = 0; k < _opts.action.options.length; k++) {
          // Find object
          for (var j = 0; j < vals.length; j++) {
            // Search values
            if (_opts.action.options[k].value == vals[j]) {
              _opts.action.value.push(_opts.action.options[k]);
            }
          }
        }
      }
    }
    // _instance.action.button = _opts.action.button;
    _instance.action.select = _opts.action;
    return _showActions(_opts);
  },
  calendar: function (_opts) {
    _checkAction(_opts);
    _opts.type = "calendar";
    _instance.action.calendar.options = _opts.action;
    return _showActions(_opts);
  },
  hours: function (_opts) {
    _checkAction(_opts);
    _opts.type = "hours";
    _instance.action.hours.options = _opts.action;
    return _showActions(_opts);
  },
  file: function (_opts) {
    // _checkAction(_opts);
    console.log(_opts)
    _opts.type = "file";
    _opts.action = Object.assign({ 
      value: null, 
      sub_type: null,
      quality: 80,
      width: 1080,
      height: 1080,
      preserveAspectRatio: true,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      direction: CameraDirection.Rear,
      saveToGallery: false
    }, _opts.action);
    _instance.action.file = _opts.action;
    return _showActions(_opts);
  },
};

export var botui = _interface;
</script>

<style lang="scss">

pwa-camera .pick-image {
  display: flex;
}

a.botui-message-content-link {
  color: var(--v-accent-base) !important;
  border-bottom: 1px dotted;

  &:active,
  &:hover {
    outline: 0;
  }
}

.botui-actions-buttons {
  text-align: right;
}

form.botui-actions-text {
  margin: 0;
}

button.botui-actions-buttons-button,
input.botui-actions-text-input {
  margin: 0;
  font-size: 100%;
  line-height: normal;
  *vertical-align: middle;
  vertical-align: baseline;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

button.botui-actions-buttons-button {
  cursor: pointer;
  *overflow: visible;
  -webkit-appearance: button;
}

.botui-app-container {
  width: 100%; // mobile-first
  height: 100%;
  line-height: 1;

  @media (min-width: 400px) {
    width: 400px;
    height: 500px;
    margin: 0 auto;
  }
}

.botui-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.datepicker .v-btn.v-btn--active {
  color: #FFFFFF;
  border-color: var(--v-primary-darken2) !important;
  background-color: transparent !important;
  border: 1px solid;
}

.botui-container .timepicker {
  max-width: 64px;
}

.botui-message {
  margin: 10px 0;
  min-height: 20px;

  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

.human .botui-message-content {
  float: right;
}

.botui-messages-container {
  padding: 16px 0;
}
.botui-actions-container .botui-actions-form:not(.botui-actions-text):not(.botui-actions-calendar):not(.botui-actions-file) {
  max-width: 90%;
}

.botui-message-content {
  padding: 8px 12px;
  border-radius: 0 8px 8px 8px !important;
  width: auto;
  max-width: 90%;
  display: inline-block;

  &.loading {
    margin-top: 16px;
  }
  &.loading .dot {
    font-size: 2rem;
    display: inline-block;
    color: white;
    &:nth-last-child(1) {
      animation: loading 1.5s .5s ease-in-out infinite;
    }
    &:nth-last-child(2) {
      margin-left: .125rem;
      margin-right: .125rem;
      animation: loading 1.5s .25s ease-in-out infinite;
    }
    &:nth-last-child(3) {
      animation: loading 1.5s .1s ease-in-out infinite;
    }
  }

  em {
    font-style: normal;
    font-weight: 500;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.botui-message {
  position: relative;
  margin: 2px 0;
  min-height: 24px;

  &.bot + .human {
    margin-top: 32px;
  }
  &.human + .bot {
    margin-top: 32px;
  }
  &.human .text {
    border-radius: 8px 8px 0 8px !important;
    background: var(--v-accent-darken4) !important;
  }
  .botui-message-footer {
    display: inline-block;
    // position: absolute;
    // bottom: -24px;
    // left: 0;
    opacity: .64;

    .botui-message-timestamp {
      display: none;
    }
  }
  &.human .botui-message-footer {
    // right: 0;
    left: auto;
  }
  &:last-of-type .botui-message-timestamp {
    display: inline-block !important;
  }
  .botui-message-content.loading ~ .bot .botui-message-timestamp {
    display: none !important;
  }
}


.botui-message-content-image {
  margin: -4px -8px;
  border-radius: 4px;
  display: block;
  max-width: 280px;
  max-height: 280px;
}

.botui-message-content-link {
  text-decoration: underline;
}

.botui-actions-buttons-button {
  display: inline-block;
  text-align: left;
  width: auto !important;
  max-width: 90%;
  background: transparent !important;
  
  & span {
    white-space: normal;
    word-break: normal;
    line-height: 1.5;
    max-width: 100%;
  }
}

.botui-actions-text-submit {
  @media (min-width: 400px) {
    display: none;
  }
}

.botui-action-button-icon.fa {
  background-size: contain;
  display: block;
}

.botui-actions-buttons-button-icon {
  background: none;
  box-shadow: none;
}

.botui-actions-text-area textarea {
  margin-top: 14px !important;
  margin-bottom: 8px;
}

/*
  Animation of loading dots
*/

@keyframes loading {
  0% {
    transform: translate(0, 0);
    // background-color: lighten($primary-color, 10%);
  }

  25% {
    transform: translate(0, .25rem);
  }
  50% {
    transform: translate(0, 0px);
    // background-color: lighten($primary-color, 10%);
  }
  75% {
    transform: translate(0, .25rem);
  }
  100% {
    transform: translate(0, 0px);
  }
}

</style>